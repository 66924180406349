import React, { ReactElement, useEffect, useState } from 'react'
import Radom, { IManagedPaymentMethodDetails, IManagedPaymentStatus, IPaymentLink, IPaymentLinkOrder, InputFieldWithValue } from '../api/Radom'
import PresaleComponent from '../components/Presale'
import LoadingPresale from '../components/LoadingPresale'

function PresaleView({ paymentLinkId }): ReactElement {
  const [isLoading, setIsLoading] = useState(true)
  const [paymentLinkData, setPaymentLinkData] = useState<IPaymentLink>()

  const [isPreProcessingPayment, setIsPreProcessingPayment] = useState(false)
  const [order, setOrder] = useState<IPaymentLinkOrder>()
  const [managedPaymentDetails, setManagedPaymentDetails] = useState<IManagedPaymentMethodDetails>()
  const [paymentStatus, setPaymentStatus] = useState<IManagedPaymentStatus>()

  const [inputFields, setInputFields] = useState<InputFieldWithValue[]>([])

  const [errorMessage, setErrorMessage] = useState<string>()

  console.log('hi')

  useEffect(() => {
    console.log('getting apyemnt link')
    Radom.getPaymentLink(paymentLinkId).then(data => {
      setPaymentLinkData(data)

      if (data.inputFields) {
        const inputFields: InputFieldWithValue[] = []
        for (const input of data.inputFields) {
          inputFields.push({ ...input, value: '' })
        }
        setInputFields(inputFields)
      }
    }).finally(() => {
      setIsLoading(false)
    })
  }, [])

  if (!paymentLinkData || paymentLinkData.products.length <= 0 || isLoading) {
    return <LoadingPresale />
  }

  return <PresaleComponent
    checkoutData={{
      ...paymentLinkData,
      inputFields,
      products: paymentLinkData.products.map(p => {
        return {
          product: p,
          quantity: 1
        }
      })
    }}
    onCheckout={async ({ selectedMethod, tokenAmount }) => {
      try {
        setErrorMessage(undefined)
        setIsPreProcessingPayment(true)

        const orderRes = await Radom.createPaymentLinkOrder({
          paymentLinkId,
          paymentMethod: { managed: { method: selectedMethod } },
          orderData: [
            ...inputFields.map(c => {
              return {
                key: c.inputLabel,
                value: c.value.toString()
              }
            }),
            {
              key: 'Token amount',
              value: tokenAmount
            }
          ]
        })
        setOrder(orderRes)
        setIsPreProcessingPayment(false)

        if (orderRes.paymentMethodDetails.managed) {
          setManagedPaymentDetails(orderRes.paymentMethodDetails.managed)
        }

        const checkPaymentInterval = setInterval(() => {
          if (!orderRes.paymentMethodDetails.managed) {
            return
          }

          Radom.getManagedPaymentStatus(orderRes.paymentMethodDetails.managed?.paymentId)
            .then(res => {
              setPaymentStatus(res)

              if (res.status === 'paymentConfirmed') {
                if (window?.parent) {
                  window.parent.postMessage({
                    orderId: orderRes.id,
                    status: res.status
                  }, '*')
                }

                clearInterval(checkPaymentInterval)
              }
            }).catch(() => {})
        }, 1000)
      } catch (err) {
        setErrorMessage(err.reason || err.message)
        setIsPreProcessingPayment(false)
      }
    }}
    order={order}
    managedPaymentDetails={managedPaymentDetails}
    errorMessage={errorMessage}
    isPreProcessingPayment={isPreProcessingPayment}
    paymentStatus={paymentStatus}
    onInputFieldsChange={setInputFields}
    isSuccess={false}
    successElement={<></>}
  />
}

export default PresaleView
