import React, { ReactElement } from 'react'
import { WagmiProvider } from 'wagmi'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { wagmiConfig } from './util/Managed'
import Presale from './views/Presale'

const queryClient = new QueryClient()

function App({ presaleId }): ReactElement {
  return <WagmiProvider config={wagmiConfig}>
    <QueryClientProvider client={queryClient}>
      {/* <Container>wtf</Container> */}
      <Presale paymentLinkId={presaleId} />
    </QueryClientProvider>
  </WagmiProvider>
}

export default App
