import QRCode from 'qrcode'
import { IManagedPaymentMethodDetails } from '../api/Radom'
import { EVMToken, SolanaToken, TronToken, getMethod } from './Managed'
import { parseEther, parseUnits } from 'viem'

async function loadImage(src: any): Promise<HTMLImageElement> {
  const image = new Image()
  image.crossOrigin = 'anonymous'
  return await new Promise((resolve) => {
    image.onload = () => {
      resolve(image)
    }
    image.src = src
  })
}

export async function generateQRCode(
  paymentMethodDetails: IManagedPaymentMethodDetails,
  darkColor: string = '#000000',
  lightColor: string = '#ffffff',
  drawRadius = true
): Promise<[string, string]> {
  let dataUrl = ''

  const { network, token, paymentAddress, amount } = paymentMethodDetails
  const paymentMethod = getMethod(network, token)

  switch (paymentMethod.name) {
  case 'Bitcoin':
  case 'BitcoinTestnet': {
    dataUrl = `bitcoin:${paymentAddress}?value=${amount}`
    break
  }
  case 'Litecoin': {
    dataUrl = `litecoin:${paymentAddress}}?amount=${amount}`
    break
  }
  case 'Ethereum':
  case 'SepoliaTestnet':
  case 'Polygon':
  case 'PolygonTestnet':
  case 'BNB':
  case 'BNBTestnet':
  case 'Arbitrum':
  case 'Avalanche':
  case 'Base':
  case 'ArbitrumTestnet': {
    dataUrl = `ethereum:${paymentAddress}?value=${parseEther(amount)}`
    break
  }
  case 'SolanaDevnet':
  case 'SolanaTestnet':
  case 'Solana': {
    dataUrl = `solana:${paymentAddress}?value=${amount}`
    break
  }
  case 'Zcash':
  case 'ZcashTestnet': {
    dataUrl = `zcash:${paymentAddress}?value=${amount}`
    break
  }
  case 'Dogecoin': {
    dataUrl = `dogecoin:${paymentAddress}?value=${amount}`
    break
  }
  case 'Tron':
  case 'TronTestnet': {
    dataUrl = `tron:${paymentAddress}?value=${parseUnits(amount, 6)}`
    break
  }
  case 'PolkadotTestnet':
  case 'Polkadot': {
    dataUrl = paymentAddress
    break
  }
  }

  switch (paymentMethod.tokenInfo?.networkName) {
  case 'Ethereum':
  case 'SepoliaTestnet':
  case 'Polygon':
  case 'PolygonTestnet':
  case 'BNB':
  case 'BNBTestnet':
  case 'Arbitrum':
  case 'Avalanche':
  case 'Base':
  case 'ArbitrumTestnet': {
    const { tokenAddress, chainId, decimals } = paymentMethod.tokenInfo as EVMToken
    dataUrl = `ethereum:${tokenAddress}@${chainId}/transfer?address=${paymentAddress}&uint256=${parseUnits(amount, decimals)}`
    break
  }

  case 'Solana':
  case 'SolanaDevnet':
  case 'SolanaTestnet': {
    const { tokenAddress, decimals, cluster } = paymentMethod.tokenInfo as SolanaToken
    dataUrl = `solana:${tokenAddress}@${cluster}/transfer?address=${paymentAddress}&uint256=${parseUnits(amount, decimals)}`
    break
  }

  case 'Tron':
  case 'TronTestnet': {
    const { tokenAddress, decimals } = paymentMethod.tokenInfo as TronToken
    dataUrl = `tron:${tokenAddress}/transfer?address=${paymentAddress}&uint256=${parseUnits(amount, decimals)}`
  }
  }

  const width = 500
  const height = 500

  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height

  QRCode.toCanvas(
    canvas,
    dataUrl,
    {
      errorCorrectionLevel: 'H',
      margin: 1,
      color: {
        dark: darkColor,
        light: lightColor
      },
      width: 500
    }
  )

  const ctx = canvas.getContext('2d')
  if (!ctx) {
    console.error('Failed to get canvas')
    return await Promise.reject(new Error('Failed to get canvas context'))
  }

  const img = await loadImage(paymentMethod.qrCodeImage || paymentMethod.logo)
  const maxLogoSize = 100

  ctx.beginPath()
  ctx.fillStyle = '#ffffff'

  const outlineSize = 20

  if (paymentMethod.tokenInfo) {
    const imgMax = Math.max(img.width, img.height)
    const imgWidth = img.width / imgMax * maxLogoSize
    const imgHeight = img.height / imgMax * maxLogoSize

    const centerX = (500 - imgWidth) / 2
    const centerY = (500 - imgHeight) / 2

    if (drawRadius && ctx.roundRect) {
      ctx.roundRect(
        200 - outlineSize / 2, 200 - outlineSize / 2,
        maxLogoSize + outlineSize, maxLogoSize + outlineSize,
        90
      )
      ctx.fill()
    }

    const chainImage = await loadImage(paymentMethod.tokenInfo.logo)
    const chainImgMax = Math.max(chainImage.width, chainImage.height)
    const chainImgWidth = chainImage.width / chainImgMax * maxLogoSize / 2
    const chainImgHeight = chainImage.height / chainImgMax * maxLogoSize / 2

    if (drawRadius && ctx.roundRect) {
      ctx.roundRect(
        centerX + (maxLogoSize / 2) + outlineSize - 14, centerX + (maxLogoSize / 2) + outlineSize - 14,
        70, 70,
        90
      )
      ctx.fill()
    }

    ctx.drawImage(img, centerX, centerY, imgWidth, imgHeight)
    ctx.drawImage(chainImage,
      centerX + maxLogoSize / 2 + outlineSize + ((Math.max(chainImgWidth, chainImgHeight) - chainImgWidth) / 2),
      centerY + maxLogoSize / 2 + outlineSize + ((Math.max(chainImgWidth, chainImgHeight) - chainImgHeight) / 2),
      chainImgWidth,
      chainImgHeight
    )
  } else {
    const imgMax = Math.max(img.width, img.height)
    const imgWidth = img.width / imgMax * maxLogoSize
    const imgHeight = img.height / imgMax * maxLogoSize

    const centerX = (500 - imgWidth) / 2
    const centerY = (500 - imgHeight) / 2

    if (drawRadius && ctx.roundRect) {
      ctx.roundRect(
        200 - outlineSize / 2, 200 - outlineSize / 2,
        maxLogoSize + outlineSize, maxLogoSize + outlineSize,
        90
      )
      ctx.fill()
    }
    ctx.drawImage(img, centerX, centerY, imgWidth, imgHeight)
  }

  const newImg = canvas.toDataURL('image/png')
  return [newImg, dataUrl]
}
