import BTCLogo from '../images/BTCLogo.svg'
import EthLogo from '../images/EthLogo.svg'
import BNBLogo from '../images/BNBLogo.svg'
import DogeLogo from '../images/DogeLogo.svg'
import SolLogo from '../images/Solana.png'
import LitecoinLogo from '../images/LitecoinLogo.svg'
import DashLogo from '../images/DashLogo.svg'
import CardanoLogo from '../images/CardanoLogo.svg'
import MoneroLogo from '../images/XMRLogo.svg'
import UDSTLogo from '../images/TetherLogo.svg'
import USDCLogo from '../images/USDCLogo.svg'
import PolygonLogo from '../images/PolygonLogo.svg'
import DogeDog from '../images/Doge.png'
import ZcashLogo from '../images/ZcashLogo.svg'
import BatLogo from '../images/BAT.svg'
import BUSDLogo from '../images/BUSDLogo.svg'
import DotLogo from '../images/DOT.svg'
import TronLogo from '../images/TronLogo.svg'
import VerseLogo from '../images/VerseLogo.png'
import ArbitrumLogo from '../images/ArbitrumLogo.svg'
import AvalancheLogo from '../images/AvalancheLogo.svg'
import BaseLogo from '../images/BaseLogo.svg'
import { mainnet, polygon, polygonMumbai, bsc, bscTestnet, arbitrum, arbitrumGoerli, avalanche, base } from 'wagmi/chains'
import { defaultWagmiConfig } from '@web3modal/wagmi'

export const NetworkToChainMap = {
  Ethereum: mainnet,
  Avalanche: avalanche,
  Base: base,
  SepoliaTestnet: {
    id: 11155111,
    network: 'sepolia',
    name: 'Sepolia',
    nativeCurrency: {
      name: 'Sepolia Ether',
      symbol: 'SEP',
      decimals: 18
    },
    rpcUrls: {
      alchemy: {
        http: ['https://eth-sepolia.g.alchemy.com/v2'],
        webSocket: ['wss://eth-sepolia.g.alchemy.com/v2']
      },
      infura: {
        http: ['https://sepolia.infura.io/v3'],
        webSocket: ['wss://sepolia.infura.io/ws/v3']
      },
      default: {
        http: ['https://rpc2.sepolia.org']
      },
      public: {
        http: ['https://rpc2.sepolia.org']
      }
    },
    blockExplorers: {
      etherscan: {
        name: 'Etherscan',
        url: 'https://sepolia.etherscan.io'
      },
      default: {
        name: 'Etherscan',
        url: 'https://sepolia.etherscan.io'
      }
    },
    contracts: {
      multicall3: {
        address: '0xca11bde05977b3631167028862be2a173976ca11',
        blockCreated: 6507670
      }
    },
    testnet: true
  },
  Polygon: polygon,
  PolygonTestnet: polygonMumbai,
  BNB: bsc,
  BNBTestnet: bscTestnet,
  Arbitrum: arbitrum,
  ArbitrumTestnet: arbitrumGoerli
}

const chains = Object.values(NetworkToChainMap) as any
export const projectId = 'e13bb5b25a3121bba8a056c8dadd0c1c'
export const metadata = {
  name: 'Radom',
  description: 'Decentralized payments',
  url: 'https://radom.com',
  icons: []
}

export const wagmiConfig = defaultWagmiConfig({
  chains,
  projectId,
  metadata
})

interface BaseToken {
  tokenType: string
  tokenAddress: string
  decimals: number
  networkName: string
  logo: any
}

export interface EVMToken extends BaseToken {
  tokenType: 'evm'
  chainId: number
  subscriptionContract?: string
}

export interface SolanaToken extends BaseToken {
  tokenType: 'solana'
  cluster: string
}

export interface TronToken extends BaseToken {
  tokenType: 'tron'
}

export interface ManagedPaymentMethod {
  name: string
  logo?: any
  qrCodeImage?: any
  currencyName?: string
  ticker: string
  tokenInfo?: EVMToken | SolanaToken | TronToken
  isTestnet?: boolean
  isSolana?: boolean
}

export const getPaymentMethods = (): ManagedPaymentMethod[] => [
  {
    name: 'Bitcoin',
    logo: BTCLogo,
    ticker: 'BTC'
  },
  {
    name: 'BitcoinTestnet',
    currencyName: 'Bitcoin',
    logo: BTCLogo,
    ticker: 'BTC',
    isTestnet: true
  },
  {
    name: 'Ethereum',
    currencyName: 'Ether',
    logo: EthLogo,
    ticker: 'ETH'
  },
  {
    name: 'SepoliaTestnet',
    currencyName: 'Ether',
    logo: EthLogo,
    ticker: 'ETH',
    isTestnet: true
  },
  {
    name: 'Polygon',
    currencyName: 'Matic',
    logo: PolygonLogo,
    ticker: 'MATIC'
  },
  {
    name: 'Avalanche',
    currencyName: 'Avalanche',
    logo: AvalancheLogo,
    ticker: 'AVAX'
  },
  {
    name: 'PolygonTestnet',
    currencyName: 'Matic',
    logo: PolygonLogo,
    ticker: 'MATIC',
    isTestnet: true
  },
  {
    name: 'BNB',
    currencyName: 'BNB',
    logo: BNBLogo,
    ticker: 'BNB'
  },
  {
    name: 'BNBTestnet',
    currencyName: 'BNB',
    logo: BNBLogo,
    ticker: 'BNB',
    isTestnet: true
  },
  {
    name: 'Arbitrum',
    currencyName: 'ETH',
    logo: ArbitrumLogo,
    ticker: 'ETH'
  },
  {
    name: 'Base',
    currencyName: 'ETH',
    logo: BaseLogo,
    ticker: 'ETH'
  },
  {
    name: 'Sepolia USD Coin',
    ticker: 'USDC',
    logo: USDCLogo,
    tokenInfo: {
      tokenType: 'evm',
      chainId: 11155111,
      tokenAddress: '0xa4fCE8264370437e718aE207805b4e6233638b9E',
      networkName: 'SepoliaTestnet',
      logo: EthLogo,
      decimals: 6,
      subscriptionContract: '0x87Fcf739aEe21FAb3b99121fA4f2D7bF243f9F15'
    },
    isTestnet: true
  },
  {
    name: 'Devnet USD Coin',
    ticker: 'USDC',
    logo: USDCLogo,
    tokenInfo: {
      tokenType: 'solana',
      tokenAddress: '4zMMC9srt5Ri5X14GAgXhaHii3GnPAEERYPJgZJDncDU',
      networkName: 'SolanaDevnet',
      cluster: 'SolanaDevnet',
      logo: SolLogo,
      decimals: 6
    },
    isTestnet: true,
    isSolana: true
  },
  {
    name: 'Sepolia Tether',
    ticker: 'USDT',
    logo: UDSTLogo,
    tokenInfo: {
      tokenType: 'evm',
      chainId: 11155111,
      tokenAddress: '0xE50d86c6dE38F9754f6777d2925377564Bf79482',
      networkName: 'SepoliaTestnet',
      logo: EthLogo,
      decimals: 6,
      subscriptionContract: '0x87Fcf739aEe21FAb3b99121fA4f2D7bF243f9F15'
    },
    isTestnet: true
  },
  {
    name: 'Basic Attention Token',
    ticker: 'BAT',
    logo: BatLogo,
    tokenInfo: {
      tokenType: 'evm',
      chainId: 11155111,
      tokenAddress: '0x5D684d37922dAf7Aa2013E65A22880a11C475e25',
      networkName: 'SepoliaTestnet',
      logo: EthLogo,
      decimals: 18,
      subscriptionContract: '0x87Fcf739aEe21FAb3b99121fA4f2D7bF243f9F15'
    },
    isTestnet: true
  },
  {
    name: 'Polygon Testnet USD Coin',
    ticker: 'USDC',
    logo: USDCLogo,
    tokenInfo: {
      tokenType: 'evm',
      chainId: 80001,
      tokenAddress: '0x8f8b1972eea072C3C228EbE8f9FEADe03927D70F',
      networkName: 'PolygonTestnet',
      logo: PolygonLogo,
      decimals: 6,
      subscriptionContract: '0x87Fcf739aEe21FAb3b99121fA4f2D7bF243f9F15'
    },
    isTestnet: true
  },
  {
    name: 'Polygon Testnet Tether',
    ticker: 'USDT',
    logo: UDSTLogo,
    tokenInfo: {
      tokenType: 'evm',
      chainId: 80001,
      tokenAddress: '0x70BE8802e2F3C6652B7e0814B478f66Ec52d9d88',
      networkName: 'PolygonTestnet',
      logo: PolygonLogo,
      decimals: 6,
      subscriptionContract: '0x87Fcf739aEe21FAb3b99121fA4f2D7bF243f9F15'
    },
    isTestnet: true
  },
  {
    name: 'Polygon Testnet BAT',
    ticker: 'BAT',
    logo: BatLogo,
    tokenInfo: {
      tokenType: 'evm',
      chainId: 80001,
      tokenAddress: '0xd445cAAbb9eA6685D3A512439256866563a16E93',
      networkName: 'PolygonTestnet',
      logo: PolygonLogo,
      decimals: 18,
      subscriptionContract: '0x87Fcf739aEe21FAb3b99121fA4f2D7bF243f9F15'
    },
    isTestnet: true
  },
  {
    name: 'Tether',
    ticker: 'USDT',
    logo: UDSTLogo,
    tokenInfo: {
      tokenType: 'evm',
      chainId: 1,
      tokenAddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
      networkName: 'Ethereum',
      logo: EthLogo,
      decimals: 6,
      subscriptionContract: '0x87Fcf739aEe21FAb3b99121fA4f2D7bF243f9F15'
    }
  },
  {
    name: 'Tether',
    ticker: 'USDT',
    logo: UDSTLogo,
    tokenInfo: {
      tokenType: 'evm',
      chainId: 137,
      tokenAddress: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
      networkName: 'Polygon',
      logo: PolygonLogo,
      decimals: 6,
      subscriptionContract: '0x87Fcf739aEe21FAb3b99121fA4f2D7bF243f9F15'
    }
  },
  {
    name: 'Tether',
    ticker: 'USDT',
    logo: UDSTLogo,
    tokenInfo: {
      tokenType: 'evm',
      chainId: 56,
      tokenAddress: '0x55d398326f99059ff775485246999027b3197955',
      networkName: 'BNB',
      logo: BNBLogo,
      decimals: 18,
      subscriptionContract: '0x87Fcf739aEe21FAb3b99121fA4f2D7bF243f9F15'
    }
  },
  {
    name: 'USD Coin',
    ticker: 'USDC',
    logo: USDCLogo,
    tokenInfo: {
      tokenType: 'evm',
      chainId: 56,
      tokenAddress: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
      networkName: 'BNB',
      logo: BNBLogo,
      decimals: 18,
      subscriptionContract: '0x87Fcf739aEe21FAb3b99121fA4f2D7bF243f9F15'
    }
  },
  {
    name: 'USD Coin',
    ticker: 'USDC',
    logo: USDCLogo,
    tokenInfo: {
      tokenType: 'evm',
      chainId: 8453,
      tokenAddress: '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913',
      networkName: 'Base',
      subscriptionContract: '0x61dD58140201Af4E77B67a36BaA25e1e560d87E9',
      logo: BaseLogo,
      decimals: 6
    }
  },
  {
    name: 'USD Coin',
    ticker: 'USDC',
    logo: USDCLogo,
    tokenInfo: {
      tokenType: 'evm',
      chainId: 42161,
      tokenAddress: '0xaf88d065e77c8cc2239327c5edb3a432268e5831',
      networkName: 'Arbitrum',
      logo: ArbitrumLogo,
      decimals: 6
    }
  },
  {
    name: 'Bridged USD Coin',
    ticker: 'USDC.e',
    logo: USDCLogo,
    tokenInfo: {
      tokenType: 'evm',
      chainId: 42161,
      tokenAddress: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
      networkName: 'Arbitrum',
      logo: ArbitrumLogo,
      decimals: 6
    }
  },
  {
    name: 'Tether',
    ticker: 'USDT',
    logo: UDSTLogo,
    tokenInfo: {
      tokenType: 'evm',
      chainId: 42161,
      tokenAddress: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
      networkName: 'Arbitrum',
      logo: ArbitrumLogo,
      decimals: 6
    }
  },
  {
    name: 'Tether',
    ticker: 'USDT',
    logo: UDSTLogo,
    tokenInfo: {
      tokenType: 'evm',
      chainId: 43114,
      tokenAddress: '0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7',
      networkName: 'Avalanche',
      logo: AvalancheLogo,
      decimals: 6
    }
  },
  {
    name: 'Binance USD',
    ticker: 'BUSD',
    logo: BUSDLogo,
    tokenInfo: {
      tokenType: 'evm',
      chainId: 56,
      tokenAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
      networkName: 'BNB',
      logo: BNBLogo,
      decimals: 18,
      subscriptionContract: '0x87Fcf739aEe21FAb3b99121fA4f2D7bF243f9F15'
    }
  },
  {
    name: 'Basic Attention Token',
    ticker: 'BAT',
    logo: BatLogo,
    tokenInfo: {
      tokenType: 'evm',
      chainId: 56,
      tokenAddress: '0x101d82428437127bf1608f699cd651e6abf9766e',
      networkName: 'BNB',
      logo: BNBLogo,
      decimals: 18,
      subscriptionContract: '0x87Fcf739aEe21FAb3b99121fA4f2D7bF243f9F15'
    }
  },
  {
    name: 'USD Coin',
    ticker: 'USDC',
    logo: USDCLogo,
    tokenInfo: {
      tokenType: 'evm',
      chainId: 1,
      tokenAddress: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
      networkName: 'Ethereum',
      logo: EthLogo,
      decimals: 6,
      subscriptionContract: '0x87Fcf739aEe21FAb3b99121fA4f2D7bF243f9F15'
    }
  },
  {
    name: 'Basic Attention Token',
    ticker: 'BAT',
    logo: BatLogo,
    tokenInfo: {
      tokenType: 'evm',
      chainId: 1,
      tokenAddress: '0x0d8775f648430679a709e98d2b0cb6250d2887ef',
      networkName: 'Ethereum',
      logo: EthLogo,
      decimals: 18,
      subscriptionContract: '0x87Fcf739aEe21FAb3b99121fA4f2D7bF243f9F15'
    }
  },
  {
    name: 'Verse',
    ticker: 'VERSE',
    logo: VerseLogo,
    tokenInfo: {
      tokenType: 'evm',
      chainId: 1,
      tokenAddress: '0x249ca82617ec3dfb2589c4c17ab7ec9765350a18',
      networkName: 'Ethereum',
      logo: EthLogo,
      decimals: 18,
      subscriptionContract: '0x87Fcf739aEe21FAb3b99121fA4f2D7bF243f9F15'
    }
  },
  {
    name: 'Bridged USD Coin',
    ticker: 'USDC.e',
    logo: USDCLogo,
    tokenInfo: {
      tokenType: 'evm',
      chainId: 137,
      tokenAddress: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
      networkName: 'Polygon',
      logo: PolygonLogo,
      decimals: 6,
      subscriptionContract: '0x87Fcf739aEe21FAb3b99121fA4f2D7bF243f9F15'
    }
  },
  {
    name: 'USD Coin',
    ticker: 'USDC',
    logo: USDCLogo,
    tokenInfo: {
      tokenType: 'evm',
      chainId: 137,
      tokenAddress: '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359',
      networkName: 'Polygon',
      logo: PolygonLogo,
      decimals: 6,
      subscriptionContract: '0x87Fcf739aEe21FAb3b99121fA4f2D7bF243f9F15'
    }
  },
  {
    name: 'USD Coin',
    ticker: 'USDC',
    logo: USDCLogo,
    tokenInfo: {
      tokenType: 'evm',
      chainId: 137,
      tokenAddress: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
      networkName: 'Polygon',
      logo: PolygonLogo,
      decimals: 6,
      subscriptionContract: '0x87Fcf739aEe21FAb3b99121fA4f2D7bF243f9F15'
    }
  },
  {
    name: 'Basic Attention Token',
    ticker: 'BAT',
    logo: BatLogo,
    tokenInfo: {
      tokenType: 'evm',
      chainId: 137,
      tokenAddress: '0x3cef98bb43d732e2f285ee605a8158cde967d219',
      networkName: 'Polygon',
      logo: PolygonLogo,
      decimals: 18,
      subscriptionContract: '0x87Fcf739aEe21FAb3b99121fA4f2D7bF243f9F15'
    }
  },
  {
    name: 'Solana',
    logo: SolLogo,
    ticker: 'SOL',
    isSolana: true
  },
  {
    name: 'SolanaTestnet',
    logo: SolLogo,
    ticker: 'SOL',
    isTestnet: true,
    isSolana: true
  },
  {
    name: 'SolanaDevnet',
    logo: SolLogo,
    ticker: 'SOL',
    isTestnet: true,
    isSolana: true
  },
  {
    name: 'Polkadot',
    logo: DotLogo,
    ticker: 'DOT'
  },
  {
    name: 'PolkadotTestnet',
    logo: DotLogo,
    ticker: 'WND',
    isTestnet: true
  },
  {
    name: 'Solana Testnet USD Coin',
    logo: USDCLogo,
    ticker: 'USDC',
    isTestnet: true,
    tokenInfo: {
      tokenType: 'solana',
      cluster: 'testnet',
      tokenAddress: 'CpMah17kQEL2wqyMKt3mZBdTnZbkbfx4nqmQMFDP5vwp',
      networkName: 'SolanaTestnet',
      logo: USDCLogo,
      decimals: 6
    },
    isSolana: true
  },
  {
    name: 'Solana Devnet USD Coin',
    logo: USDCLogo,
    ticker: 'USDC',
    isTestnet: true,
    tokenInfo: {
      tokenType: 'solana',
      cluster: 'devnet',
      tokenAddress: '4zMMC9srt5Ri5X14GAgXhaHii3GnPAEERYPJgZJDncDU',
      networkName: 'SolanaDevnet',
      logo: USDCLogo,
      decimals: 6
    },
    isSolana: true
  },
  {
    name: 'Tether',
    ticker: 'USDT',
    logo: UDSTLogo,
    tokenInfo: {
      tokenType: 'solana',
      cluster: 'mainnet',
      tokenAddress: 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
      networkName: 'Solana',
      logo: SolLogo,
      decimals: 6
    },
    isSolana: true
  },
  {
    name: 'USD Coin',
    ticker: 'USDC',
    logo: USDCLogo,
    tokenInfo: {
      tokenType: 'solana',
      cluster: 'mainnet',
      tokenAddress: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
      networkName: 'Solana',
      logo: SolLogo,
      decimals: 6
    },
    isSolana: true
  },
  {
    name: 'Basic Attention Token',
    ticker: 'BAT',
    logo: BatLogo,
    tokenInfo: {
      tokenType: 'solana',
      cluster: 'mainnet',
      tokenAddress: 'EPeUFDgHRxs9xxEPVaL6kfGQvCon7jmAWKVUHuux1Tpz',
      networkName: 'Solana',
      logo: SolLogo,
      decimals: 8
    },
    isSolana: true
  },
  {
    name: 'Cardano',
    logo: CardanoLogo,
    ticker: 'ADA'
  },
  {
    name: 'Monero',
    logo: MoneroLogo,
    ticker: 'XMR'
  },
  {
    name: 'Dogecoin',
    logo: DogeLogo,
    ticker: 'DOGE',
    qrCodeImage: DogeDog
  },
  {
    name: 'Litecoin',
    logo: LitecoinLogo,
    ticker: 'LTC'
  },
  {
    name: 'Dash',
    logo: DashLogo,
    ticker: 'DASH'
  },
  {
    name: 'Zcash',
    logo: ZcashLogo,
    ticker: 'ZEC'
  },
  {
    name: 'ZcashTestnet',
    logo: ZcashLogo,
    ticker: 'ZEC',
    isTestnet: true
  },
  {
    name: 'Tron',
    logo: TronLogo,
    ticker: 'TRX'
  },
  {
    name: 'Tether',
    ticker: 'USDT',
    logo: UDSTLogo,
    tokenInfo: {
      tokenType: 'tron',
      networkName: 'Tron',
      tokenAddress: 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t',
      logo: TronLogo,
      decimals: 6
    }
  },
  {
    name: 'TronTestnet',
    logo: TronLogo,
    ticker: 'TRX',
    isTestnet: true
  },
  {
    name: 'Tether',
    ticker: 'USDT',
    logo: UDSTLogo,
    tokenInfo: {
      tokenType: 'tron',
      networkName: 'TronTestnet',
      tokenAddress: 'TSrUgwP9NPHKMcWPtHRRWcoxFV4JU6R9qk',
      logo: TronLogo,
      decimals: 6
    },
    isTestnet: true
  }
]

export const getMethod = (network: string, token?: string): ManagedPaymentMethod => {
  const method = getPaymentMethods().find(m => {
    if (token) {
      return m.tokenInfo?.tokenAddress.toLowerCase() === token.toLowerCase() &&
       network.toLowerCase() === m.tokenInfo?.networkName.toLowerCase()
    } else {
      return m.name.toLowerCase() === network.toLowerCase()
    }
  })

  if (!method) {
    const method = getPaymentMethods().find(m => {
      return m.name.toLowerCase() === network.toLowerCase()
    })
    if (method) {
      return method
    }
  }

  if (!method) {
    return {
      name: 'Unknown',
      logo: null,
      ticker: 'Unknown'
    }
  }

  return method
}

export const isAutopayEnabled = (m?: ManagedPaymentMethod): boolean => {
  if (!m) {
    return false
  }

  if (m.tokenInfo) {
    if (m.tokenInfo.tokenType === 'evm' && m.tokenInfo.subscriptionContract) {
      return true
    }
  }

  return false
}
