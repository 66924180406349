import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'

console.info(`
██████╗  █████╗ ██████╗  ██████╗ ███╗   ███╗
██╔══██╗██╔══██╗██╔══██╗██╔═══██╗████╗ ████║
██████╔╝███████║██║  ██║██║   ██║██╔████╔██║
██╔══██╗██╔══██║██║  ██║██║   ██║██║╚██╔╝██║
██║  ██║██║  ██║██████╔╝╚██████╔╝██║ ╚═╝ ██║
╚═╝  ╚═╝╚═╝  ╚═╝╚═════╝  ╚═════╝ ╚═╝     ╚═╝
Welcome to Radom!
Build: ${process.env.RADOM_BUILD_VERSION}
`)

window.LoadPresale = (elementId, presaleId) => {
  const root = createRoot(document.getElementById(elementId)!)
  root.render(<App presaleId={presaleId} />)
}
// console.log('fuck')
